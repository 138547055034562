<template>
  <v-app-bar
    :clipped-left="$vuetify.breakpoint.lgAndUp"
    clipped-right
    app
    elevation="1"
    color="white"
  >
    <v-img
      contain
      :max-width="portal_name == 'xylem' ? '160px' : '100px'"
      height="50px"
      :src="logo"
      alt="EOMAP Logo"
    ></v-img>
    <v-spacer />
    <v-btn rounded color="primary" class=" ml-2 " @click="login">
      {{ $t("welcomePage.loginBtn") }}
    </v-btn>
    <v-btn
      v-if="$appConfig.keycloakClient !== 'coasts'"
      text
      color="primary"
      class="ml-2 mr-2"
      @click="$keycloak.register()"
    >
      {{ $t("welcomePage.registerBtn") }}
    </v-btn>
    <v-menu offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-btn icon v-bind="attrs" v-on="on">
          {{ currentLocale }}
        </v-btn>
      </template>
      <v-list>
        <v-list-item
          v-for="locale in $i18n.availableLocales"
          :key="`locale-${locale}`"
          @click="setLocale(locale)"
        >
          <v-list-item-title>{{
            locale.toString().toUpperCase()
          }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    <a
      class="ml-2"
      v-if="
        $appConfig.keycloakClient !== 'coasts' &&
          $appConfig.keycloakClient !== 'xylem'
      "
      v-bind:href="'mailto:wq@eomap.de'"
      ><v-icon :title="$t('contactForm.contactUs')"
        >mdi-email-outline</v-icon
      ></a
    >
    <a
      class="ml-2"
      v-if="$appConfig.keycloakClient === 'xylem'"
      v-bind:href="'mailto:eoapp@xylem.com; xylem@eomap.de'"
      ><v-icon :title="$t('contactForm.contactUs')"
        >mdi-email-outline</v-icon
      ></a
    >
  </v-app-bar>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "bar",
  data: function() {
    return {
      portal_name: this.$appConfig.name,
      logo: this.$appConfig.logo,
      currentLocale:
        localStorage.getItem("preferredLocale") || this.$i18n.locale
    };
  },
  computed: {
    ...mapState("app", ["showMenu"])
  },
  methods: {
    login() {
      this.$keycloak.login();
    },
    setLocale(locale) {
      this.$i18n.locale = locale;
      this.currentLocale = locale;
      localStorage.setItem("preferredLocale", locale);
    }
  },
  created() {
    const preferredLocale = localStorage.getItem("preferredLocale");
    if (preferredLocale) {
      this.setLocale(preferredLocale);
    }
  }
};
</script>

<style scoped>
#eo-tool-btns {
  margin-left: 1rem;
}
</style>
